import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { css } from "@emotion/react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

import config from '../../website-config';
import './SiteNavLogo.css'

interface SiteNavLogoProps {
  logo?: {
    childImageSharp: {
      fixed: GatsbyImage;
    };
  };
  logoMobile?: {
    childImageSharp: {
      fixed: GatsbyImage;
    };
  };
}

export const SiteNavLogo = () => (
  <StaticQuery
    query={graphql`query HeadingQuery {
  logo: file(relativePath: {eq: "img/logoshortenedMLC.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 500, layout: FIXED)
    }
  }
  logoMobile: file(relativePath: {eq: "img/logo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 500, layout: FIXED)
    }
  }
}
`}
    // tslint:disable-next-line:react-this-binding-issue
    render={(data: SiteNavLogoProps) => (
      <span id="site-nav-logo-span">
      <Link className="site-nav-logo" to="/">
        {data.logo ? (
          <img src={getSrc(data.logo.childImageSharp.gatsbyImageData)} alt={config.title} />
        ) : (
          config.title
        )}
      </Link>

      {data.logo ? (
          <img className="site-nav-logo-tablet" src={getSrc(data.logo.childImageSharp.gatsbyImageData)} alt={config.title} />
        ) : (
          config.title
        )}

      {data.logoMobile ? (
          <img className="site-nav-logo-mobile" src={getSrc(data.logoMobile.childImageSharp.gatsbyImageData)} alt={config.title} />
        ) : (
          config.title
        )}
        
      
      </span>
    )}
  />
);

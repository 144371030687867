export const colors = {
  blue: '#3eb0ef',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: '#15171A',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  pink: '#fa3a57',
  brown: '#a3821a',
  // darkmode: color(var(--darkgrey) l(+2%)),
  darkmode: '#121e30',

  // dark mode colors 
  adaptiveBlueBg: "rgb(4 10 17 / 35%)",
  mainDarkBlue: "#05111f",
  lighterDarkBlue: "#07182c",
  blueBorder: "#26a6d8",
  blueButton: "#172940",

};

import { Link } from 'gatsby';
import { backgroundImages, setLightness } from 'polished';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';

import { useCookies, Cookies } from 'react-cookie';
import CookieConsentBanner from './CookieConsent';

import { GTAG_OPTIN_KEY } from 'gatsby-plugin-google-gtag-optin'; // Or use your own if you changed it in the config

export const Footer: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'rcl_consent_given',
    'rcl_statistics_consent',
  ]);
  const [resetCookieFlag, setResetCookieFlag] = useState(false); //this is a dummy value. It's purpose is to make React re-render the banner because a state value has changed.
  const [resetCookiesText, setResetCookiesText] = useState('Reset Cookie Preferences');
  const cookieRef = new Cookies();

  function removeCookies() {
    removeCookie('rcl_consent_given');
    removeCookie('rcl_statistics_consent');
    removeCookie('rcl_marketing_consent');
    if (process.env.NODE_ENV === 'production') {
      gaOptout();
    }
    localStorage.clear();
    setResetCookieFlag(prevState => {
      return !prevState;
    });
  }

  /*useEffect(() => {
    console.log("kms")
    let reloading = localStorage.getItem("reloading");
    if (reloading) {
        console.log("wait what")
        sessionStorage.removeItem("reloading");
        console.log(localStorage)
        removeCookies();
    }
  });*/

  function resetCookies() {
    setResetCookiesText('Done! The page will now reload.');
    removeCookies();
    let cookieBanner = document?.getElementById('CookieBanner');
    cookieBanner != null && cookieBanner.classList.add('hideBeforeReload');
    window.location.reload();
  }

  let handleAcceptCookies = () => {
    let analyticsCookie = cookieRef.get('rcl_statistics_consent');
    if (analyticsCookie == 'true') {
      localStorage.setItem(GTAG_OPTIN_KEY, 'true');
      let gtagLoaded = window.localStorage.getItem('gtagLoaded');
      if (typeof window.loadGtag == 'function' && gtagLoaded != 'true') {
        window.loadGtag();
        window.localStorage.setItem('gtagLoaded', 'true');
      }
    }
  };

  return (
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <SiteFooterNav>
          <a href="/rss.xml">RSS</a>
          {/*<a href="/rss.xml">Contact</a>*/}
          {/*<a href="/rss.xml">Support the project</a>*/}
          {/* <Link to="/imprint_and_privacy_policy_(EN)">Imprint & Privacy Policy (EN)</Link> */}

          <a onClick={resetCookies} css={{ background: 'transparent', cursor: 'pointer' }}>
            {resetCookiesText}
          </a>

          {/* <Link to="/imprint_and_privacy_policy_(DE)">Impressum & Datenschutz (DE)</Link> */}
        </SiteFooterNav>
        <section className="copyright">
          <Link to="/">{config.title}</Link> &copy; {new Date().getFullYear()}{' '}
          {config.footer && (
            <Link to="/">
              | {config.title} {config.footer}
            </Link>
          )}
        </section>
      </div>
      <CookieConsentBanner onAcceptCallback={handleAcceptCookies} />
    </footer>
  );
};

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  background: ${'linear-gradient(180deg, #0b0d1a, #0a0c16)'};

  .copyright {
    width: 100%;
    text-align: center;
  }
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  /*display: flex;*/
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  width: 100%;
  padding-bottom: 2em;
  text-align: center;

  a {
    position: relative;
    margin-left: 20px;
  }

  /*a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }*/

  /*a:first-of-type:before {
    display: none;
  }*/

  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }
`;

import React from 'react';
import "./SubscribeForm2.css";


class SubscribeForm extends React.Component {
  render() {
    return (
      <form
        action="https://app.convertkit.com/forms/1606656/subscriptions"
        className="seva-form formkit-form"
        method="post"
        data-sv-form="1606656"
        data-uid="296c2bf9c2"
        data-format="inline"
        data-version="5"
        data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
        min-width="400 500 600 700 800"
        style={{ backgroundColor: 'rgb(31, 90, 153)', borderRadius: '4px', margin:"auto"}}
      ><div style={{textAlign:"center"}}>
          <div data-style="minimal">
          <div className="formkit-header" data-element="header" style={{ color: "rgb(255, 255, 255)", fontSize: "27px", fontWeight: "bold" }}>
            <h1>
              Join my Newsletter
            </h1>
          </div>
          <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
          <div className="formkit-subheader" data-element="subheader" style={{ color: "rgb(207, 207, 207)", fontSize: "18px" }}>
            <p>
              Subscribe to get updates and additional tips to make you machine learning project stand out!
            </p>
            <div data-element="fields" data-stacked="true" className="seva-fields formkit-fields">
              <div className="formkit-field">
                  <input className="formkit-input" aria-label="First Name" name="fields[first_name]" placeholder="First Name" type="text" style={{ color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: "normal"}} />
              </div>
              <div className="formkit-field">
                  <input className="formkit-input" name="email_address" placeholder="E-Mail address" required={true} type="email" style={{color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: "normal"}} />
              </div>
              <button data-element="submit" className="formkit-submit formkit-submit" style={{color: "rgb(255, 255, 255)", backgroundColor: "rgb(22, 119, 190)", borderRadius: "4px", fontWeight: "normal"}}>
                  <div className="formkit-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                  </div>
                  <span>Sign me up!</span>
              </button>
          <div className="formkit-guarantee" data-element="guarantee" style={{color: "rgb(223, 223, 223)", fontSize: "13px", fontWeight: "normal", textAlign: "center"}}>
            <p>One mail per month. Unsubscribe at any time.</p>
          </div>
          </div>
          </div>
        </div>
      </div>
      </form>
    );
  }
}

export default SubscribeForm;

import React from 'react';
import { css } from "@emotion/react"

const FilterDropDown = props => {

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }


    const toggleMarked = (tag: string, prefix: string = props.prefix) => {
        let clickedElement = document?.getElementById(prefix+tag)
        let alreadyChecked = clickedElement?.classList.contains("checked")
        if (alreadyChecked) {
            clickedElement!=null && clickedElement.classList?.remove("checked");
        } else {
            clickedElement!=null && clickedElement.classList?.add("checked");
        }
        props.clickCallback(tag);
    }

    let tagList = props.data
    .map(post => (
        post.node.frontmatter.tags[0])
        )
    .filter(onlyUnique)
    .sort()
    .map((tag) => {
        return ( 
          <li onClick={() => toggleMarked(tag)} id={props.prefix+tag} key={tag}>{tag}<span className="checkmark">✔️</span></li>
        )
      })


    return(
        <div css={TagList}>
            {tagList}
        </div>
    )
};

const TagList = css`
    display: block;
    list-style:none;
    li {
        display:flex;
        justify-content:space-between;
        margin:0;
        padding: 0.35em 0.5em;
        border-bottom: 0 solid #ddd;
        margin-top: 0;
        padding: 12px;
        outline: none;
    }
    li:hover {
        background:#182c4b;
        cursor:pointer;
        @media (prefers-color-scheme: light) {
            background: #c6e5ff;
        }
    }

    .checkmark {
        visibility:hidden;
        display:none;
    }

    .checked {
        background: #4bacff;
        :hover {
            background: #2198ff;
        }
        @media (prefers-color-scheme: dark) {
            background: #1f375a;
            :hover {
                background: #314e77;
            }
        }
        .checkmark {
            visibility:visible;
            display:inline-block;
        }
    }
`

export default FilterDropDown;
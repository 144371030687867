import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { CookieBanner } from '@palmabit/react-cookie-law';

import { useCookies, Cookies } from 'react-cookie';

const CookieConsentBanner = props => {
  return (
    <div id="CookieBanner">
      {/*{showGreyOut && <div id="grey" css={greyOutStyle}/>} --hide backdrop for now*/}
      <CookieBanner
        id="CookieBanner"
        message={
          <>
            <p>
              We use cookies. Currently, non-essential cookies are only used to enable Google
              Analytics.
            </p>
            <p>We use Analytics to determine which content is popular amongst readers.</p>
            <p>
              For more information on data processing you can read the privacy policy linked below.
            </p>
            <p>You can reset your preferences by clicking the link at the bottom of the page.</p>
          </>
        }
        wholeDomain={true}
        onAccept={props.onAcceptCallback}
        onAcceptStatistics={props.handleAnalyticsCheckedCallback}
        showMarketingOption={false}
        showPreferencesOption={false}
        statisticsOptionText="Allow Analytics"
        preferencesOptionText="Save Preferences"
        necessaryOptionText="Necessary Cookies"
        css={cookieConsentBannerStyle}
        policyLink="/imprint_and_privacy_policy_(EN)"
        acceptButtonText="Confirm"
        managePreferencesButtonText="Manage Cookies"
      />
    </div>
  );
};

export default CookieConsentBanner;

const cookieConsentBannerStyle = css`
  background-color: #0b1528 !important;
  bottom: 0em;
  top: auto !important;
  border: 2px solid #4db4f8;

  p {
    margin-bottom: 1px;
  }

  .react-cookie-law-msg {
    color: white !important;
  }

  .react-cookie-law-policy {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .react-cookie-law-accept-btn {
    background: #3461a4 !important;
    color: white !important;
  }

  .react-cookie-law-mgs {
    padding: 5px 0px 20px !important;
    white-space: pre-line;
  }

  label {
    line-height: 1.25em;
  }
`;

import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { css } from "@emotion/react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image";

import config from '../../website-config';

interface SiteNavLogoProps {
  logoMobile?: {
    childImageSharp: {
      fixed: GatsbyImage;
    };
  };
}

export const SiteNavLogoMobile = () => (
  <StaticQuery
    query={graphql`{
  logoMobile: file(relativePath: {eq: "img/logo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 500, layout: FIXED)
    }
  }
}
`}
    // tslint:disable-next-line:react-this-binding-issue
    render={(data: SiteNavLogoProps) => (
        <>
        {data.logoMobile ? (
          <img css={siteNavLogoMobileStyle} src={getSrc(data.logoMobile.childImageSharp.gatsbyImageData)} alt={config.title} />
        ) : (
          config.title
        )}
        </>
    )}
  />
);

const SiteNavLogoStyles = css`
  position: relative;
  z-index: 100;
  flex-shrink: 0;
  display: inline-block;
  height: 21px;

  /* removed for search popup */
  /*margin-right: 16px;
  margin-left: 16px;
  padding: 12px 0;*/ 
  /* added for search popup */
  margin: 0;
  padding: 0;
  top: 4px;
  left: 4px;

  color: #fff;
  font-size: 1.7rem;
  line-height: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-transform: none;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 21px;
  }
`;

const siteNavLogoMobileStyle = css`
  display: inline-block;
  width: auto;
  height: 21px;
  transform: translateY(-2px);
  margin-left: 0.2em;
`;

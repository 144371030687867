import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import './Search.css'

import { css } from '@emotion/react'

import { PageContext } from '../templates/post';
import { SiteNavLogoMobile } from './header/SiteNavLogoMobile';
import FilterDropDown from './FilterDropDown'

import Filter from './icons/filter'

import { format } from 'date-fns';
import { daysBetween, getCurrentDate } from '../utils'

import { Virtuoso } from 'react-virtuoso'

export interface SearchData {
    pageContext: {
      currentPage: number;
      numPages: number;
    };
    data: {
      allMdx: {
        group: Array<{
          tag: string,
          totalCount: number
        }>
        edges: Array<{
          node: PageContext;
        }>;
      };
    };
  }

export const Search = props => {

    const data = useStaticQuery(graphql`query searchQuery{
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
        group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
        }
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            uniquePostId
            draft
            date
          }
        }
      }
    }
    }`)

    const [inputVal, setInputVal] = useState("");
    const [cursor, setCursor] = useState(0);
    const [filterTags, setFilterTags] = useState([""]);

    let currentDate = getCurrentDate()

    const addRemoveFilterTag = (tag: string) => {
      if (filterTags.includes(tag)) {
        let newFilterTags = filterTags.filter(entry => entry!=tag)
        setFilterTags(newFilterTags)
      } else {
        setFilterTags([...filterTags,tag])
      }
    }

    const itemList = data.allMdx.edges.filter(post => {
      let postTitle = post.node.frontmatter.title.toLowerCase();
      let useFilterTags = filterTags.length==1;
      let postTag = post.node.frontmatter.tags[0];
      return ( postTitle.includes(inputVal.toLowerCase()) && (useFilterTags || filterTags.includes(postTag)))
    })
    .filter((post) => (process.env.NODE_ENV=="development" || post.node.frontmatter.draft===false))
    /*.map((post,idx) => {
      const date = new Date(post.node.frontmatter.date);
      const datetime = format(date, 'yyyy-MM-dd');
      const isRecent = daysBetween(datetime,currentDate)<=3;
      const isDraft = !post.node.frontmatter.draft;
      let itemClassName = ""
      isRecent && (itemClassName += " recentItem")
      !isDraft && (itemClassName += " draftItem")
      return ( 
        <li onMouseEnter={() => setCursor(idx)} className={cursor==idx? 'active' : ''} id={idx} key={idx} ><a className={itemClassName} key={post.node.frontmatter.uniquePostId} href={post.node.fields.slug} target="_blank">{post.node.frontmatter.title}</a></li>
      )
    })*/

    const Row = ({index}) => {
      const post = itemList[index]
      const date = new Date(post.node.frontmatter.date);
      const datetime = format(date, 'yyyy-MM-dd');
      const isRecent = daysBetween(datetime,currentDate)<=3;
      const isDraft = !post.node.frontmatter.draft;
      let itemClassName = ""
      isRecent && (itemClassName += " recentItem")
      !isDraft && (itemClassName += " draftItem")
      return ( 
        <li onMouseEnter={() => setCursor(index)} className={cursor==index? 'searchResultsItem active' : 'searchResultsItem'} id={index} key={index} ><a className={itemClassName} key={post.node.frontmatter.uniquePostId} href={post.node.fields.slug} target="_blank">{post.node.frontmatter.title}</a></li>
      )
    };

    const [numItems, setNumItems] = useState(data.allMdx.totalCount);
    const [showInfo, setShowInfo] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [escapeListenerAdded, setEscapeListenerAdded] = useState(false);

    function handleChange(e){
      setInputVal(document.getElementById("searchInput").value)
      setNumItems(document.getElementsByClassName("searchResultsList")[0]?.children[0]?.children.length)
    }

      function scrollToTargetAdjusted(direction="up") {
        if (direction=="up") {
        document.getElementsByClassName("active")[0].scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
        } else {
          document.getElementsByClassName("active")[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });}
        }

      const virtuoso = useRef(null)


      function handleKeyDown(e) {
        setNumItems(document.getElementsByClassName("searchResultsList")[0]?.children[0]?.children.length)
        if (document.getElementById(String(cursor))==null) {
          setCursor(0)
      }
        //e.preventDefault();
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38) {
          e.preventDefault();
          if (cursor>0) {
          setCursor(prevPosition => prevPosition - 1)
          scrollToTargetAdjusted("up")
          //document.getElementsByClassName("active")[0].scrollIntoView()
        } else {
          /*setCursor(itemList.length-1)
          setTimeout(function() {
            scrollToTargetAdjusted("down")
          }, 10);*/

          setCursor(itemList.length-1)
          virtuoso.current.scrollToIndex({
            index: itemList.length-1,
            align: "start",
            behavior: "smooth"
          });



        }
        } else if (e.keyCode === 40) {
          e.preventDefault();
          setNumItems(itemList.length)
          if (cursor<itemList.length-1) {
          e.preventDefault();
          setCursor(prevPosition => prevPosition + 1)
          scrollToTargetAdjusted("down")
          } else if (cursor==itemList.length-1 && cursor!=0){
            /*setCursor(0)
            setTimeout(function() {
              scrollToTargetAdjusted("up")
            }, 10);*/

            setCursor(0)
            virtuoso.current.scrollToIndex({
              index: 0,
              align: "start",
              behavior: "smooth"
            });
            
          }
        } else if (e.keyCode === 27) {
          props.closeSearch()
        } else if (e.keyCode === 13) {
          let markedElement = document.getElementsByClassName("active")[0]
          markedElement.children[0].click()
        }
        /*setTimeout(function() {
        if (document.getElementById(String(cursor))==null) {
          if (cursor!=null)
          //setCursor(0)
          console.log("fffffff")
      } }, 10);*/
        
      }

      function handleScroll(e) {
        
        let d = e.originalEvent.deltaY;
        let dir = d < 0 ? 'up' : 'down';
        searchResultsList = document.getElementsByClassName("searchResultsList")[0]?.children[0]
        if ((dir == 'up' && searchResultsList.scrollTop == 0) || 
        (dir == 'down' && searchResultsList.scrollTop == searchResultsList.scrollHeight-searchResultsList.offsetHeight)) {
          e.preventDefault();
        }
      };

      function closeInfo() {
        document.removeEventListener("keydown", closeInfoIfEscapePressed)
        setShowInfo(false)
        setTimeout(function(){
          let searchInput = document.getElementById("searchInput")
          searchInput!=null && searchInput.focus()
        }, 10);
      }

      function openInfo() {
        setShowInfo(true);
        document.addEventListener("keydown", closeInfoIfEscapePressed) 
      }

      function closeFilters() {
        document.removeEventListener("keydown", closeFiltersIfEscapePressed)
        setShowFilters(false)
        setTimeout(function(){
          let searchInput = document.getElementById("searchInput")
          searchInput!=null && searchInput.focus()
        }, 10);
      }

      function openFilters() {
        setShowFilters(true);
        document.addEventListener("keydown", closeFiltersIfEscapePressed) 
      }

      function closeFiltersIfEscapePressed(e) {
        if (e.key=="Escape") {
          closeFilters();
        }
    }

      function closeInfoIfEscapePressed(e) {
          if (e.key=="Escape") {
            closeInfo();
          }
      }

      const handleKeyPressSpecial = useCallback((e) =>  {
        let infoShown = document.getElementById("searchInfo").className=="showInfo"
        let filtersShown = document.getElementById("searchFilters").className=="showFilters"
        if (e.key=="Escape" && !infoShown && !filtersShown) {
          props.closeSearch()
        } else if (e.key=="Enter") {
          // Cancel the default action, if needed
          e.preventDefault();
          // Trigger the button element with a click
          document.getElementsByClassName("active")[0].children[0].click();
        }
      }, [])

      handleKeyPressSpecial.bind(this)

      useEffect(() => {
        if (props.show) {
          if (!escapeListenerAdded) {
            window.addEventListener('keydown', handleKeyPressSpecial,true)
            setEscapeListenerAdded(true)
            setTimeout(function(){
              let searchInput = document.getElementById("searchInput")
              searchInput!=null && searchInput.focus()
            }, 100);
            
          }
        } else {
          if (escapeListenerAdded) {
          window.removeEventListener('keydown', handleKeyPressSpecial,true)
          setEscapeListenerAdded(false)
          }
        }
      })

    return(
    <div className={props.show? 'Centered': 'HideSearch'} id="search">

      <div id="searchInfo" className={showInfo? 'showInfo': 'hideInfo'}>
      <header className="info-header">
        <h3>Info</h3>
        <div className="info-svg-div">
        <svg className="close-icon" onClick={() => {closeInfo()}} fill="none" height="22" width="22" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </div>
        </header>
        <hr className="info-divider"/>
        <div className="showInfoText">
      <p>
          Here you can search for any machine learning related term and find exactly what you were looking for.
          If there is a topic that I have not covered yet, please write me about it (you can find my contact details <Link to="/contact">here</Link>)! I would love
          to hear which topic you want to see covered next! :)
          <br/>
          Btw, you can also use keyboard shortcuts to open and close the search window. 😎
        </p>
        <span>Open Search:  <strong>Ctrl + Shift + F</strong></span>
        <span>Close Search:   <strong>Escape</strong></span>
        </div>
      </div>

      <div id="searchFilters" className={showFilters? 'showFilters': 'hideFilters'}>
      <header className="filters-header">
        <h3>Filter by Tags</h3>
        <div className="filters-svg-div">
        <svg className="close-icon" onClick={() => {closeFilters()}} fill="none" height="22" width="22" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </div>
        </header>
        <hr className="filters-divider"/>
        <div className="showFiltersTagList">
          <FilterDropDown prefix="searchFilter_" clickCallback={(t: string) => {addRemoveFilterTag(t)}} data={data.allMdx.edges}/>
      </div>
      </div>

        <header className="search-header">
        <div>
        <SiteNavLogoMobile/>
        <h3 className="mlc-search-text">MLC Search</h3>
        </div>
        <div className="svg-div">
        {/*<span className="tree-icon" onClick={() => {console.log("tree")}}><Filter size="20" /></span>*/}
        <span className="filter-icon" onClick={() => {openFilters()}}><Filter size="20" /></span>
        {/*<Link css={{transform: "translateY(-5.1px)"}} to="/SearchPage"><svg className="expand-icon" height="19" width="19" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M18 10.82a1 1 0 0 0-1 1V19a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7.18a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3v-7.18a1 1 0 0 0-1-1zm3.92-8.2a1 1 0 0 0-.54-.54A1 1 0 0 0 21 2h-6a1 1 0 0 0 0 2h3.59L8.29 14.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0L20 5.41V9a1 1 0 0 0 2 0V3a1 1 0 0 0-.08-.38z" fill="currentColor"/><rect x="0" y="0" width="24" height="24" fill="none" /></svg></Link>*/}
        <svg className="info-icon" onClick={() => {openInfo()}} fill="none" height="20" width="20" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12" y2="17"></line></svg>
        <svg className="close-icon" onClick={props.closeSearch} fill="none" height="22" width="22" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></div>
        </header>

        
        <hr className="search-divider"/>
        <input autoFocus onChange={handleChange} onKeyDown={ handleKeyDown } type="text" id="searchInput" placeholder="Search for a term.." title="Type here"></input>
        <div>
        {/*<ul id="searchResultsList">*/}


        {/*<FixedSizeList
        className="searchResultsList"
        height={350}
        itemCount={itemList.length}
        itemSize={50}
        width={"100%"}
        >
        {Row}
        </FixedSizeList>*/}

        <Virtuoso css={virtuosoStyles}
        totalCount={itemList.length}
        className="searchResultsList"
        ref={virtuoso}
        itemContent={index => <Row index={index}/>}
        />



            

        {/*</ul>*/}
        </div>
        </div>
    )};



export default Search;

const virtuosoStyles = css`
height:400px !important;
@media(max-height: 600px) {
  height:350px !important;
}
@media(max-height: 500px) {
  height:275px !important;
}
@media(max-height: 400px) {
  height:175px !important;
}
@media(max-height: 300px) {
  height:125px !important;
}
`
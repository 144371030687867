import React from 'react';
import './SearchBackdrop.css'

const Backdrop = props => {

    let clickAndHide = () => {
        props.click();
    }

    return(
    <div id="searchBackdrop" className={props.show? 'ShowBackdrop': 'HideBackdrop'} onClick={clickAndHide} />
    )
};

export default Backdrop;